import { ToastContainer } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled, { ThemeProvider } from 'styled-components';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import { useCrossSellInterstitialToggle } from '@ecomm/feature-toggle/CrossSellInterstitial';
import type { FilterGroupsType } from '@ecomm/product-recommendations/models/ProductGridPropsSubTypes';
import { canModuleBeRendered, State } from '@ecomm/product-recommendations/models/State';
import type {
  GridTheme,
  PageBuilderTheme,
} from '@ecomm/product-recommendations/utils/theme';
import {
  DEFAULT_THEME_NAME,
  toGridTheme,
} from '@ecomm/product-recommendations/utils/theme';
import toGridOptions from '@ecomm/product-recommendations/utils/toGridOptions';
import useFilteredItems from '@ecomm/product-recommendations/utils/useFilteredItems';
import useProductsForCohort from '@ecomm/product-recommendations/utils/useProductsForCohort';
import {
  FiltersContext,
  useFiltersState,
} from '@ecomm/product-recommendations/Views/FiltersContext';
import { media } from '@page-builder/utils/helpers';
import { useCartFilter } from '../utils/useCart';

type Props = {
  cohort: ProductRecommendationsCohort;
  themeName?: PageBuilderTheme;
  filterGroups?: FilterGroupsType[];
};

const ProductRecommendationsGridV2: React.FC<Props> = ({
  cohort,
  filterGroups,
  themeName = DEFAULT_THEME_NAME,
}) => {
  const filtersState = useFiltersState(filterGroups);
  const filteredCohortProducts = useFilteredItems(
    cohort.products,
    filtersState.enabledFilterTags,
  );
  const gridOptions = toGridOptions(cohort, filteredCohortProducts.length);

  const {
    verticalPadding,
    horizontalPadding,
    minHeight,
    removeOuterContainerTheme,
    ctaType,
    moduleComponent: ModuleComponent,
    tileComponent: TileComponent,
    loadingComponent: LoadingComponent,
    buildTimeTileComponent: BuildTimeTileComponent,
    maxTileCount,
    showLoadingState,
  } = gridOptions;

  const { products, state } = useProductsForCohort(
    cohort,
    filtersState.enabledFilterTags,
    showLoadingState,
  );

  const { Track } = useTracking({
    properties: {
      parentType: 'ProductGrid (CHAMP)',
      parent: cohort.cohortKeyName,
    },
  });

  const gridTheme = toGridTheme(themeName);

  const { isExperimentActive, activeVariation } = useCrossSellInterstitialToggle();
  const isCrossSellInterstitialEnabled = isExperimentActive && activeVariation === 'on';

  const { renderedProducts: productsFilteredFromCart } = useCartFilter(products);
  const renderedProducts = isCrossSellInterstitialEnabled
    ? productsFilteredFromCart
    : products;
  const endSlice = maxTileCount ?? renderedProducts.length;

  return (
    <ThemeProvider theme={gridTheme}>
      <Track>
        <FiltersContext.Provider value={filtersState}>
          <GridOuterContainer
            theme={removeOuterContainerTheme ? undefined : themeName}
            gridTheme={gridTheme}
            verticalPadding={verticalPadding}
            horizontalPadding={horizontalPadding}
            maxWidth="100%"
            minHeight={minHeight}
          >
            {state === State.Loading && <LoadingComponent />}
            {canModuleBeRendered(state) && (
              <ModuleComponent
                cohort={cohort}
                themeName={themeName}
                options={gridOptions}
              >
                {state === State.CanBeBuildTimeRendered &&
                  filteredCohortProducts.map(tile => (
                    <BuildTimeTileComponent
                      key={tile.slug}
                      tile={tile}
                      themeName={themeName}
                    />
                  ))}

                {state === State.ProductDataLoaded &&
                  renderedProducts
                    .slice(0, endSlice)
                    .map(product => (
                      <TileComponent
                        key={product.slug}
                        product={product}
                        ctaType={ctaType}
                        themeName={themeName}
                      />
                    ))}
              </ModuleComponent>
            )}
          </GridOuterContainer>
        </FiltersContext.Provider>
      </Track>
      <StyledToastContainer />
    </ThemeProvider>
  );
};

const GridOuterContainer = styled(OuterContainer)<{
  gridTheme: GridTheme;
  minHeight: number;
}>`
  min-height: ${props => props.minHeight}px;
  background-color: ${props => props.gridTheme.backgroundColor};
  color: ${props => props.gridTheme.textColor};
`;

const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container {
    top: 3.5em;

    ${media.tablet`
    top: 4.25em;
  `}

    ${media.desktop`
    top: 5.5em;
  `}
  }
`;

export default ProductRecommendationsGridV2;
