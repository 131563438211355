import {
  Container,
  Flex,
  Headline,
  grey,
  spacing,
  white,
  Label,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { BreakpointWidth, media } from '@peloton/styles';
import { Carousel } from './Carousel';
import { TabCard } from './TabCard';
import type {
  TypeCardListItem as CardListItem,
  TypeTabListItem as TabListItem,
} from './types';

const CLICKED_CATEGORY_TAB_EVENT_TYPE = 'Clicked Link';
const CLICKED_SEE_MORE_CLASSES_EVENT_TYPE = 'Clicked Link';

export type TabsListingProps = {
  headline: string;
  items: TabListItem[];
};

const TabsListing: React.FC<React.PropsWithChildren<TabsListingProps>> = ({
  items,
  headline,
}) => {
  const { trackEvent } = useTracking();

  const [activeTab, setActiveTab] = useState(0);

  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);

  const trackClickedCategoryTab = (linkName: string) => {
    trackEvent({
      event: CLICKED_CATEGORY_TAB_EVENT_TYPE,
      properties: {
        parent: 'Prospect Classes Page',
        linkTo: '',
        linkName: `${linkName}`,
        unitName: 'TabsListing',
        parentType: 'Page',
      },
    });
  };

  const trackClickedSeeMoreClasses = (linkName: string, linkTo: string) => {
    trackEvent({
      event: CLICKED_SEE_MORE_CLASSES_EVENT_TYPE,
      properties: {
        parent: 'Prospect Classes Page',
        linkTo: `${linkTo}`,
        linkName: `${linkName}`,
        unitName: 'TabsListing',
        parentType: 'Page',
      },
    });
  };

  const handleTabClick = (index: number) => {
    const linkName: string = items[index].tabName;
    trackClickedCategoryTab(linkName);
    setActiveTab(index);
  };

  const tabsPanel = items[activeTab];

  const handleSeeMoreClassesClick = (linkName: string, linkTo: string) => {
    trackClickedSeeMoreClasses(linkName, linkTo);
  };
  return (
    <Container
      backgroundColor={grey[90]}
      padding={{
        mobile: `${spacing[32]} 0 0 ${spacing[16]}`,
        tablet: `0 ${spacing[32]} ${spacing[64]} ${spacing[32]}`,
        desktop: `0 ${spacing[64]} ${spacing[80]} ${spacing[64]}`,
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        style={{ width: '100%' }}
        centered
        maxWidth="1224px"
      >
        <Headline
          size="extraSmall"
          style={{ fontWeight: 600, marginBottom: spacing[16], color: white }}
        >
          {headline}
        </Headline>
        <Flex flexDirection="column">
          <TabList role="tablist" aria-orientation="horizontal" data-test-id={'tab-list'}>
            {items.map((tab: TabListItem, index) => (
              <div key={`tab-${tab.tabName}`}>
                <StyledTab
                  key={tab.tabName}
                  role="tab"
                  data-test-id={`tab-${tab.tabName}`}
                  onClick={() => handleTabClick(index)}
                  isActive={activeTab === index}
                >
                  <TabLabel isActive={activeTab === index} size="medium">
                    {tab.tabName}
                  </TabLabel>
                </StyledTab>
              </div>
            ))}
          </TabList>
          {isMobileOrTablet || tabsPanel.items.length > 3 ? (
            <Carousel preventOnFocusHandler activeTab={activeTab}>
              {tabsPanel.items.map((card: CardListItem) => (
                <TabCard
                  key={card.link.url + card.classDisplayName}
                  image={card.image}
                  classDisplayName={card.classDisplayName}
                  link={card.link}
                  instructorName={card.instructorName}
                  disciplineDisplayName={card.disciplineDisplayName}
                  difficultyLevel={card.difficultyLevel}
                />
              ))}
            </Carousel>
          ) : (
            <StyledCards>
              {tabsPanel.items &&
                tabsPanel.items.map((card: CardListItem) => (
                  <TabCard
                    key={card.link.url + card.classDisplayName}
                    image={card.image}
                    classDisplayName={card.classDisplayName}
                    link={card.link}
                    difficultyLevel={card.difficultyLevel}
                    instructorName={card.instructorName}
                    disciplineDisplayName={card.disciplineDisplayName}
                  />
                ))}
            </StyledCards>
          )}
          <div>
            {tabsPanel.ctas && (
              <StyledLink
                to={tabsPanel.ctas[0].link.url}
                href={tabsPanel.ctas[0].link.url}
                size="small"
                data-test-id="seeMoreClassesLink"
                onClick={() =>
                  handleSeeMoreClassesClick(
                    tabsPanel.ctas?.[0].text || '',
                    tabsPanel.ctas?.[0].link.url || '',
                  )
                }
              >
                {tabsPanel.ctas[0].text}
              </StyledLink>
            )}
          </div>
        </Flex>
      </Flex>
    </Container>
  );
};

const StyledCards = styled(Flex)`
  gap: ${spacing[24]};
  max-width: 100%;
  overflow: hidden;
`;

const TabList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing[8]};
  font-weight: 500;
  margin-bottom: ${spacing[24]};
`;

const StyledTab = styled.button<{ isActive: boolean }>`
  border-radius: ${spacing[8]};
  background-color: ${grey[80]};
  color: ${white};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: ${spacing[8]} ${spacing[16]};
  height: ${spacing[40]};
  justify-content: center;
  text-align: center;
  ${props => `border: 1.5px solid ${props.isActive ? white : grey[80]}`};
  &:hover {
    background-color: #383a3c;
  }
`;

const TabLabel = styled(Label)<{ isActive: boolean }>`
  font-weight: ${props => (props.isActive ? `500` : `400`)};
`;

const StyledLink = styled(Link)`
  color: #fff;
  margin-top: ${spacing[8]};
  margin-bottom: ${spacing[32]};
  ${media.tablet`
      margin-bottom: 0;
      margin-top: ${spacing[24]};
  `}
`;

export default TabsListing;
